// Imports
import React from 'react';

// CSS
// import * as FOFStyles from "./css/FOF.module.css";

// Components
import Layout from '../components/Layout';

// ======== Component ========
const FOF = () => {
	return (
		<Layout title={'Page not found.'}>
			<p>There are currently no products for sale in this category.</p>
		</Layout>
	);
};

export default FOF;
